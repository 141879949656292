import React, {Component} from "react";
import qs from "querystring";

import "./index.css";

import Article from "../../elements/article";
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

export default class MarkdownViewer extends Component {
    constructor() {
        super();

        this.state = {
            markdownRaw: ""
        }
    }

    componentDidMount() {
        hideCaptchaBadge(true);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);

        let file = qs.parse(this.props.location.search, { ignoreQueryPrefix: true})['?url'];

        if (file !== undefined)
        {
            axios.get(`/file-proxy?url=${file}`).then((res) => {
                this.setState({
                    markdownRaw: res.data
                })
            })
        }
    }

    render() {
        return (
            <Article markdownRaw={this.state.markdownRaw} />
        );
    }
}